// src/components/animations/FadeInOnScroll.js
import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

const AnimatedBox = styled(Box)`
  opacity: ${props => props.ratio};
  transform: translateY(${props => {
    if (props.ratio > 0.1) return '0px';
    return props.isScrollingDown ? '40px' : '-40px';
  }});
  filter: blur(${props => (1 - props.ratio) * 5}px);
  transition: none;
  will-change: transform, opacity, filter;
`;

const FadeInOnScroll = ({ children }) => {
  const [ref, entry, previousY] = useIntersectionObserver({
    rootMargin: '-100px 0px -100px 0px',  // Added top margin to detect exit
  });

  const ratio = Math.min(entry.intersectionRatio * 1.5, 1);
  const currentY = entry.boundingClientRect?.y || 0;
  const isScrollingDown = currentY < previousY;

  return (
    <AnimatedBox
      ref={ref}
      ratio={ratio}
      isScrollingDown={isScrollingDown}
    >
      {children}
    </AnimatedBox>
  );
};

export default FadeInOnScroll;
// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    primary: {
      main: '#ff007f',
      dark: '#cc0066',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bbbbbb',
    },
  },
  typography: {
    fontFamily: '"Quicksand", sans-serif',
    h1: {
      fontFamily: '"Poiret One", cursive',
      fontWeight: 700,
      color: '#ffffff',
    },
    h2: {
      fontFamily: '"Poiret One", cursive',
      fontWeight: 700,
      color: '#ffffff',
    },
    h4: {
      fontFamily: '"Poiret One", cursive',
      fontWeight: 700,
      color: '#ffffff',
    },
  },
});

export default theme;
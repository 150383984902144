// src/components/core/Footer.js
import React from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import styled from '@emotion/styled';

const FooterContainer = styled(Box)`
  background-color: ${props => props.theme.palette.background.paper};
  color: ${props => props.theme.palette.text.primary};
  padding: 1rem;
  width: 100%;
  margin-top: auto;
`;

const SocialLink = styled('a')`
  color: ${props => props.theme.palette.text.primary};
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 0 1rem;

  @media (max-width: 600px) {
    margin: 0.5rem;
  }

  &:hover {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const SocialContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

function Footer() {
  return (
    <FooterContainer component="footer">
      <Container maxWidth="lg">
        <SocialContainer>
          <SocialLink href="https://twitter.com/StakeSquid" target="_blank" rel="noopener noreferrer">
            <IconButton size="small" sx={{ color: 'inherit', mr: 1 }}>
              <TwitterIcon />
            </IconButton>
            @StakeSquid
          </SocialLink>

          <SocialLink href="https://t.me/StakeSquid" target="_blank" rel="noopener noreferrer">
            <IconButton size="small" sx={{ color: 'inherit', mr: 1 }}>
              <TelegramIcon />
            </IconButton>
            @StakeSquid
          </SocialLink>

          <SocialLink href="https://github.com/StakeSquid" target="_blank" rel="noopener noreferrer">
            <IconButton size="small" sx={{ color: 'inherit', mr: 1 }}>
              <GitHubIcon />
            </IconButton>
            @StakeSquid
          </SocialLink>

          <SocialLink href="mailto:info@stakesquid.com">
            <IconButton size="small" sx={{ color: 'inherit', mr: 1 }}>
              <EmailIcon />
            </IconButton>
            info@stakesquid.com
          </SocialLink>
        </SocialContainer>

        <Typography variant="caption" align="center" display="block" sx={{ mt: 2 }}>
          Made with ❤️ by StakeSquid
        </Typography>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
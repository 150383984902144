// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container, Grid, Box } from '@mui/material';
import Nav from './components/core/Nav';
import Footer from './components/core/Footer';
import Home from './views/Home';
import AboutUs from './views/AboutUs';
import Protocols from './views/Protocols';
import styled from '@emotion/styled';

const MainContainer = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Box)`
  flex: 1;
  margin-bottom: 2rem; // Add some space before footer
`;

function App() {
  return (
    <MainContainer>
      <Nav />
      <ContentContainer>
        <Container maxWidth={false} sx={{ m: 0, p: 0 }}>
          <Grid container justifyContent="center" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={8} lg={8} sx={{ m: 0, p: 0 }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/protocols" element={<Protocols />} />
              </Routes>
            </Grid>
          </Grid>
        </Container>
      </ContentContainer>
      <Footer />
    </MainContainer>
  );
}

export default App;
// src/views/AboutUs.js
import React from 'react';
import { Container, Grid, Box, Typography, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import styled from '@emotion/styled';

const TeamSection = styled(Box)`
  margin: 5rem 0;

  @media (max-width: 768px) {
    margin: 2rem 0;
  }
`;

const MemberImage = styled('img')`
  margin: 0 auto;
  border-radius: 50%;
  width: 8.75rem;
  height: 8.75rem;
  object-fit: cover;
`;

const MemberName = styled(Typography)`
  font-size: 1.75rem;
  margin: 0.5rem 0 0 0;
`;

const MemberTitle = styled(Typography)`
  color: #f3abbb;
`;

const SocialLink = styled('a')`
  text-decoration: none;
  color: #bbf3f4;
`;

const teamMembers = [
  {
    image: 'seb.png',
    name: 'Goldberg',
    title: 'Co-founder',
    github: 'goldsquid',
    description: `
      I have always been fascinated by the notion of programming freedom.
      My urge to use technology to cross boundaries and challenge business models has led
      me to filesharing networks and crypto currencies.
    `,
  },
  {
    image: 'payne.jpg',
    name: 'Payne',
    title: 'Co-founder',
    twitter: 'indexer_payne',
    github: 'trader-payne',
    description: `
    Passionate about decentralization. Went from
    full time trading to operating a small scale enterprise datacenter in 4 years.
    Always looking forward to new challenges.
    `,
  },
];

function AboutUs() {
  return (
    <TeamSection>
      <Typography variant="h2" align="center" gutterBottom sx={{ mb: 4 }}>
        Our Team
      </Typography>

      <Container>
        <Grid container spacing={4} justifyContent="space-around">
          {teamMembers.map((member) => (
            <Grid item key={member.name} lg={4} textAlign="center">
              <MemberImage
                src={`/assets/${member.image}`}
                alt={member.name}
              />
              <MemberName variant="h3">
                {member.name}
              </MemberName>
              <MemberTitle variant="h6">
                {member.title}
              </MemberTitle>
              <Box mb={2}>
                {member.twitter && (
                  <>
                    <IconButton
                      component={SocialLink}
                      href={`https://twitter.com/${member.twitter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                      sx={{ color: 'primary.main' }}
                    >
                      <TwitterIcon />
                    </IconButton>
                    <SocialLink
                      href={`https://twitter.com/${member.twitter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @{member.twitter}
                    </SocialLink>
                  </>
                )}
                {member.github && (
                  <>
                    <IconButton
                      component={SocialLink}
                      href={`https://github.com/${member.github}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                      sx={{ color: 'primary.main', ml: member.twitter ? 2 : 0 }}
                    >
                      <GitHubIcon />
                    </IconButton>
                    <SocialLink
                      href={`https://github.com/${member.github}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @{member.github}
                    </SocialLink>
                  </>
                )}
              </Box>
              <Typography>
                {member.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </TeamSection>
  );
}

export default AboutUs;
// src/components/ProtocolsList.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import FadeInOnScroll from './animations/FadeInOnScroll';

const ProtocolSection = styled(Box)`
  margin: 5rem auto;
  max-width: 1400px;
  padding: 0 2rem;

  @media (max-width: 768px) {
    margin: 2rem 0;
    padding: 0 1rem;
  }
`;

const FadeInSection = styled(Box)`
  opacity: ${props => props.ratio};
  transform: translateY(${props => 40 * (1 - props.ratio)}px); // Changed from 20 to 40
  filter: blur(${props => (1 - props.ratio) * 5}px); // Added blur effect
  transition: none;
`;

const ProtocolImage = styled('img')`
  border-radius: 50%;
  width: 15rem;
  height: 15rem;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 12.5rem;
    height: 12.5rem;
  }
`;

const ProtocolBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  width: 100%;
  gap: 2rem;
  justify-content: ${props => props.reversed ? 'flex-end' : 'flex-start'};

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    justify-content: center;
  }
`;

const ProtocolImageContainer = styled(Box)`
  flex: 0 0 auto;
`;

const ProtocolContent = styled(Box)`
  max-width: 500px;
  text-align: ${props => props.reversed ? 'right' : 'left'};

  @media (max-width: 768px) {
    text-align: center;
    max-width: 100%;
  }
`;

const ProtocolName = styled(Typography)`
  color: #ff007f;
  margin-bottom: 0.5rem;
  font-family: 'Poiret One', cursive;
  font-weight: bold;
`;

const ProtocolDescription = styled(Typography)`
  color: #bbbbbb;
`;

const AnimatedTitle = styled(Typography)`
  opacity: ${props => props.ratio};
  transform: translateY(${props => 40 * (1 - props.ratio)}px); // Changed from 20 to 40
  filter: blur(${props => (1 - props.ratio) * 5}px); // Added blur effect
  transition: none;
`;


const protocols = [
  {
    name: "The Graph",
    image: "graph.png",
    description: "Indexing protocol for organizing and efficiently accessing data from blockchains and storage networks using GraphQL. Developers use existing subgraphs or deploy new subgraphs to build great applications on blockchains in a fraction of the time. The protocol has become an essential building block for dApps on Ethereum.",
  },
  {
    name: "Celestia",
    image: "celestia.png",
    description: "Celestia is a minimal blockchain that only orders and publishes transactions and does not execute them. By decoupling the consensus and application execution layers, Celestia modularizes the blockchain technology stack and unlocks new possibilities for decentralized application builders.",
  },
  {
    name: "Solana",
    image: "solana.png",
    description: "Fast, secure, and censorship resistant blockchain providing the open infrastructure required for global adoption.",
  },
  {
    name: "Mina",
    image: "mina.png",
    description: "The world's lightest blockchain, powered by participants. A privacy-preserving gateway between the real world and crypto — and the infrastructure for the secure, democratic future we all deserve.",
  },
  {
    name: "Moonbeam",
    image: "moonbeam.png",
    description: "Moonbeam is an Ethereum-compatible smart contract parachain on Polkadot. Moonbeam makes it easy to use popular Ethereum developer tools to build or redeploy Solidity projects in a Substrate-based environment. The Moonbeam platform extends the base Ethereum feature set with additional features such as on-chain governance, staking, and cross-chain integrations.",
  },
  {
    name: "Moonriver",
    image: "moonriver.png",
    description: "Moonriver is a companion network to Moonbeam and provides a permanently incentivized canary network. New code ships to Moonriver first, where it can be tested and verified under real economic conditions. Once proven, the same code ships to Moonbeam on Polkadot.",
  },
  {
    name: "Connext",
    image: "connext.png",
    description: "Connext is a crosschain liquidity network that enables fast, fully-noncustodial transfers between EVM-compatible chains and L2 systems. It leverages the Ethereum blockchain along with groundbreaking distributed systems tech to enable instant, near-free transfers anywhere in the world.",
  },
  {
    name: "BlastAPI",
    image: "blastapi.png",
    description: "Decentralized infrastructure for everyone. Solving Web3 reliability and performance issues by efficiently employing the resources of hundreds of third party node providers combined with a state-of-the-art API platform and an improved user experience.",
  },
  {
    name: "Pocket Network",
    image: "pocket.png",
    description: "Pocket Network Inc. is a software startup building a universal, decentralized API protocol for blockchains. It functions as an inclusive relay network for API requests to major blockchains, with a crypto-economic model that minimizes costs for developers while passing the value directly on to the full-node operators.",
  },
  {
    name: "Agoric",
    image: "agoric.png",
    description: "Smart contract platform built on the Cosmos SDK that uses Tendermint Proof-of-Stake (PoS) and a native token to secure the network. The Agoric chain enables developers to create decentralized applications (dApps) using composable JavaScript smart contracts.",
  },
  {
    name: "XX Network",
    image: "xxnetwork.png",
    description: "A consumer-scale online platform which enables users to share information and exchange value without exposing data about themselves to organizations who could gather and abuse it.",
  },
  {
    name: "Avalanche",
    image: "avalanche.png",
    description: "Open-source platform for launching highly decentralized applications, new financial primitives, and new interoperable blockchains.",
  },
  {
    name: "Arweave",
    image: "arweave.png",
    description: "A new type of storage that backs data with sustainable and perpetual endowments, allowing users and developers to truly store data forever – for the very first time. As a collectively owned hard drive that never forgets, Arweave allows us to remember and preserve valuable information, apps, and history indefinitely.",
  },
];

const Protocol = ({ protocol, index }) => {
  const isReversed = index % 2 === 1;

  return (
    <FadeInOnScroll>
      <ProtocolBox reversed={isReversed}>
        {!isReversed ? (
          <>
            <ProtocolImageContainer>
              <ProtocolImage
                src={`/assets/${protocol.image}`}
                alt={protocol.name}
              />
            </ProtocolImageContainer>
            <ProtocolContent reversed={isReversed}>
              <ProtocolName variant="h4">
                {protocol.name}
              </ProtocolName>
              <ProtocolDescription>
                {protocol.description}
              </ProtocolDescription>
            </ProtocolContent>
          </>
        ) : (
          <>
            <ProtocolContent reversed={isReversed}>
              <ProtocolName variant="h4">
                {protocol.name}
              </ProtocolName>
              <ProtocolDescription>
                {protocol.description}
              </ProtocolDescription>
            </ProtocolContent>
            <ProtocolImageContainer>
              <ProtocolImage
                src={`/assets/${protocol.image}`}
                alt={protocol.name}
              />
            </ProtocolImageContainer>
          </>
        )}
      </ProtocolBox>
    </FadeInOnScroll>
  );
};

function ProtocolsList() {
  return (
    <ProtocolSection>
      <FadeInOnScroll>
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            mb: 6,
            fontFamily: '"Poiret One", cursive',
            fontWeight: 'bold',
            color: '#ffffff'
          }}
        >
          Supported Protocols
        </Typography>
      </FadeInOnScroll>

      {protocols.map((protocol, index) => (
        <Protocol 
          key={protocol.name}
          protocol={protocol}
          index={index}
        />
      ))}
    </ProtocolSection>
  );
}


export default ProtocolsList;
// src/components/core/StakeSquidLogo.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const LogoText = styled(Typography)`
  font-family: 'Poiret One', cursive;
  font-size: 1.75rem;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span:last-child {
    color: #ff007f;
  }
`;

const LogoImage = styled('img')`
  height: 2.5rem;
  width: 2.5rem;
  object-fit: contain;
`;

const StakeSquidLogo = ({ linkTo = '/', showText = true }) => {
  const content = (
    <LogoContainer>
      {showText && (
        <LogoText component="div">
          <span>Stake</span>
          <LogoImage 
            src="/assets/stakesquid-logo.png" 
            alt="StakeSquid"
          />
          <span>Squid</span>
        </LogoText>
      )}
      {!showText && (
        <LogoImage 
          src="/assets/stakesquid-logo.png" 
          alt="StakeSquid"
        />
      )}
    </LogoContainer>
  );

  if (linkTo) {
    return (
      <Link to={linkTo} style={{ textDecoration: 'none' }}>
        {content}
      </Link>
    );
  }

  return content;
};

export default StakeSquidLogo;
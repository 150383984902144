// src/components/core/Nav.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import styled from '@emotion/styled';
import StakeSquidLogo from './StakeSquidLogo';

const NavContainer = styled(AppBar)`
  background-color: ${props => props.theme.palette.background.default};
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const NavToolbar = styled(Toolbar)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  width: 100%;
`;

const LeftSection = styled(Box)`
  justify-self: start;
`;

const CenterSection = styled(Box)`
  justify-self: center;
`;

const RightSection = styled(Box)`
  justify-self: end;
  display: flex;
  align-items: center;
`;

const NavButton = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  margin: 0 1rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    color: #ff007f;
    border-bottom: 2px solid #ff007f;
  }
  
  ${props => props.active && `
    color: #ff007f;
    border-bottom: 2px solid #ff007f;
  `}
`;

const DrawerContent = styled(List)`
  background-color: #1e1e1e;
  height: 100%;
  width: 250px;
`;

const DrawerItem = styled(ListItem)`
  padding: 1rem 1.5rem;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
`;

const DrawerItemText = styled(ListItemText)`
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  
  .MuiListItemText-primary {
    font-weight: 500;
  }
`;

const navItems = [
  { name: 'HOME', slug: '/', path: '/' },
  { name: 'ABOUT US', slug: '/about-us', path: '/about-us' },
];

function Nav() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <DrawerContent>
      {navItems.map((item) => (
        <DrawerItem
          key={item.name}
          component={Link}
          to={item.path}
          onClick={handleDrawerToggle}
          disablePadding
        >
          <DrawerItemText primary={item.name} />
        </DrawerItem>
      ))}
    </DrawerContent>
  );

  return (
    <NavContainer position="sticky" top={0}>
      <NavToolbar>
        <LeftSection />
        <CenterSection>
          <StakeSquidLogo />
        </CenterSection>
        <RightSection>
          {!isMobile && (
            <>
              {navItems.map((item) => (
                <NavButton
                  key={item.name}
                  to={item.path}
                  active={location.pathname === item.path ? 1 : 0}
                >
                  {item.name}
                </NavButton>
              ))}
            </>
          )}
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{
                color: '#ffffff',
                '&:hover': {
                  color: '#ff007f',
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </RightSection>
      </NavToolbar>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        {drawer}
      </Drawer>
    </NavContainer>
  );
}

export default Nav;
// src/hooks/useIntersectionObserver.js
import { useEffect, useState } from 'react';

export const useIntersectionObserver = (options = {}) => {
  const [ref, setRef] = useState(null);
  const [entry, setEntry] = useState({
    isIntersecting: false,
    intersectionRatio: 0,
    boundingClientRect: null
  });
  const [previousY, setPreviousY] = useState(0);

  useEffect(() => {
    if (!ref) return;

    const observer = new IntersectionObserver(([entry]) => {
      const currentY = entry.boundingClientRect.y;
      setPreviousY(currentY);
      setEntry(entry);
    }, { 
      threshold: Array.from({ length: 100 }, (_, i) => i / 100),
      ...options 
    });

    observer.observe(ref);

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, options]);

  return [setRef, entry, previousY];
};
// src/components/PartnersList.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import styled from '@emotion/styled';
import FadeInOnScroll from './animations/FadeInOnScroll';

const PartnersSection = styled(Box)`
  margin-bottom: 5rem;
  width: 100%;
`;

const PartnerImage = styled('img')`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

const PartnersRow = styled(Grid)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  padding: 0 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    
    & > div {
      margin: 1rem auto;
      width: 100%;
      max-width: 300px;
    }
  }
`;

const PartnerLink = styled('a')`
  display: block;
  width: 200px;
  height: 80px;
  
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const partners = [
  {
    name: "CryptoCalibur",
    url: "http://cryptocalibur.com/",
    image: "cryptocalibur.png"
  },
  {
    name: "Easy2Stake",
    url: "http://easy2stake.com/",
    image: "easy2stake.png"
  },
  {
    name: "BitTraffic",
    url: "https://bittraffic.com/",
    image: "bittraffic.png"
  }
];

function PartnersList() {
  return (
    <PartnersSection>
      <FadeInOnScroll>
        <Typography
          variant="h2"
          align="center"
          sx={{
            mb: 4,
            fontFamily: '"Poiret One", cursive',
            fontWeight: 'bold'
          }}
        >
          Partners
        </Typography>
      </FadeInOnScroll>

      <PartnersRow container>
        {partners.map((partner) => (
          <Grid
            item
            key={partner.name}
          >
            <FadeInOnScroll>
              <PartnerLink
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PartnerImage
                  src={`/assets/${partner.image}`}
                  alt={partner.name}
                />
              </PartnerLink>
            </FadeInOnScroll>
          </Grid>
        ))}
      </PartnersRow>
    </PartnersSection>
  );
}

export default PartnersList;
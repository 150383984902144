// src/views/Home.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import ProtocolsList from '../components/ProtocolsList';
import PartnersList from '../components/PartnersList';

const HeroSection = styled(Box)`
  margin: 20rem 7.5rem 15rem 7.5rem;

  @media (max-width: 768px) {
    margin: 2.5rem 2.5rem 7.5rem 2.5rem;
  }
`;

const MainText = styled(Typography)`
  margin: 1.5rem 0;
  color: #ff007f;  // Changed to --squid-pink from the palette
  letter-spacing: 0.125rem;

  @media (max-width: 768px) {
    font-size: 3.25rem !important;
    margin: 0.25rem 0;
  }
`;

const SubtitleText = styled(Typography)`
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 1.25rem !important;
  }
`;

function Home() {
  return (
    <div>
      <HeroSection>
        <SubtitleText variant="h4">
          Your gateway to Web3
        </SubtitleText>
        <MainText variant="h1">
          Secure Non-Custodial Staking Services
        </MainText>
        <SubtitleText variant="h4">
          We provide enterprise-grade, secure, high availability non-custodial staking services and developer tools
        </SubtitleText>
      </HeroSection>

      <ProtocolsList />
      <PartnersList />
    </div>
  );
}

export default Home;